import React from "react"
import Layout from "../../components/layout/"
import AllDipsImage from './all-dips-image'
import JustHummusImage from './just-hummus-image'
import BabaGhanoushImage from './baba-ghanoush-image'
import LabnehImage from './labneh-image'
import TzatzikiImage from './tzatziki-image'
import MuhammaraImage from './muhammara-image'
import PlainLabnehImage from './plain-labneh-image'
import { VeganBadge, GlutenFreeBadge, ShelfLifeBadge } from '../../components/product-badges'
import "./products.less"


const Products = ({ data }) => (
  <Layout>
    <div className="container container-main">
      <h1>Products</h1>

      <AllDipsImage
        className="product-splash"
      />

      <div style={{ marginBottom: "4rem" }}>
        <h2>Dips</h2>
        <p>In a world flooded with dips, what makes ours special?</p>
        <p>
          Simple ingredients. No preservatives. Each dip is a modern homage to
          classic, homestyle Lebanese recipes.
        </p>
      </div>

      <div className="product-section">
        <h3>
          <span style={{ marginRight: "0.5rem" }}>Just Hummus&trade;</span>
        </h3>
        <JustHummusImage className="product-image" />
        <div className="product-copy">
          <p>
            <VeganBadge style={{ verticalAlign: "middle" }} />
            <GlutenFreeBadge style={{ verticalAlign: "middle" }} />
            <ShelfLifeBadge numWeeks={2} />
          </p>
          <p>
            Six ingredients &mdash; chickpeas, tahini, lemon juice, olive oil,
            garlic, and salt. That&apos;s it! That&apos;s Just Hummus&trade;.
          </p>
          <p>
            This is the original of originals — the same tangy, tahini-forward
            taste you’d find in kitchens and street cafés across Lebanon.
          </p>
        </div>
      </div>

      <div className="product-section">
        <h3 style={{ clear: "both" }}>Baba Ghanoush</h3>
        <BabaGhanoushImage className="product-image" />
        <div className="product-copy">
          <p>
            <VeganBadge style={{ verticalAlign: "middle" }} />
            <GlutenFreeBadge style={{ verticalAlign: "middle" }} />
            <ShelfLifeBadge numWeeks={3} />
          </p>
          <p>
            Our baba is made with only 5 ingredients: eggplant, tahini, garlic,
            lemon juice, olive oil, and salt.
          </p>
          <p>
            Baba ghanoush is similar to hummus, but instead of chickpeas it is
            made with roasted eggplant. The roasted eggplant gives
            baba ghanoush its trademark smoky flavor.
          </p>
        </div>
      </div>

      <div className="product-section">
        <h3 style={{ clear: "both" }}>Labneh with Lebanese Za'atar</h3>
        <LabnehImage className="product-image" />
        <div className="product-copy">
          <p>
            <GlutenFreeBadge style={{ verticalAlign: "middle" }} />
            <ShelfLifeBadge numWeeks={5} />
          </p>
          <p>
            Labneh (<em>LEB-knee</em>) is a dip made from strained yogurt.
            It&apos;s similar to Greek yogurt, but labneh is usually eaten as a
            dip or a spread rather than on its own.
          </p>
          <p>
            Labneh makes a great dip for
            vegetables, a spread for toast, or a condiment for veggie
            sandwiches. As is popular in Lebanon, our labneh is mixed with
            Lebanese za&apos;atar, a spice mix made from wild thyme.
          </p>
        </div>
      </div>

      <div className="product-section">
        <h3 style={{ clear: "both" }}>Plain Labneh</h3>
        <PlainLabnehImage className="product-image" />
        <div className="product-copy">
          <p>
            <GlutenFreeBadge style={{ verticalAlign: "middle" }} />
            <ShelfLifeBadge numWeeks={5} />
          </p>
          <p>
            Our Plain Labneh is the same recipe as our Labneh with Lebanese Za'atar, only without
            the za'atar.
          </p>
        </div>
      </div>

      <div className="product-section">
        <h3 style={{ clear: "both" }}>Tzatziki</h3>
        <TzatzikiImage className="product-image" />
        <div className="product-copy">
          <p>
            <GlutenFreeBadge style={{ verticalAlign: "middle" }} />
            <ShelfLifeBadge numWeeks={5} />
          </p>
          <p>
            Our Lebanese-style Tzatziki is based on the traditional Lebanese sauce <em>laban wi chiar</em> ("yogurt with
            cucumber").
          </p>
          <p>
            Tangy and refreshing, it's great on sandwiches or dolloped onto salads!
          </p>
        </div>
      </div>

      <div className="product-section">
        <h3 style={{ clear: "both" }}>Muhammara</h3>
        <MuhammaraImage className="product-image" />
        <div className="product-copy">
          <p>
            <VeganBadge />
            <ShelfLifeBadge numWeeks={5} />
          </p>
          <p>
            Nutty with a touch of sweetness, muhammara is a dip made from roasted red pepper,
            walnut, and pomegranate.
          </p>
        </div>
      </div>

    </div>
  </Layout>
)

export default Products
